/* -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// ----------------------------------------------------------------------------- */
.header-wrap{
    padding: 25px 0 25px;
    position: relative;
    z-index: 15;
}
.journeys-logo{
    float: left;
    img{
        max-width: 247px;
        margin-top: 13px;
        @include smalldesk{
            margin-top: 0;
        }
    }
}
.main-navigation{
    float: right; 
    ul{
        list-style: none;
        padding: 0 0 0;
        margin: 0 0 0;
        li{
            list-style: none;
            padding: 23px 25px;
            margin: 0 0 0;
            line-height: inherit;
            float: left;
            a{
                font-size: 16px;
                font-weight: 500;
                color: #28275A;
                text-transform: capitalize;
                @include transition(0.4s);
                &:hover{
                    text-decoration: none;
                    color: #30C8AC;
                    @include transition(0.4s);
                }
                &:focus, &:active{
                    text-decoration: none;
                }
            }
            &.starts{
                padding-right: 0;
                padding-top: 0;
                padding-bottom: 0;
                a{
                    font-weight: 500;
                    font-size: 18px;
                    color: #FFF;
                    display: block;
                    background: #FFAB40;
                    padding: 22px 25px;
                    @include border-radius(10px);
                    min-width: 176px;
                    text-align: center;
                    text-decoration: none;
                    @include transition(0.4s);
                    &:hover{
                        text-decoration: none;
                        @include transition(0.4s);
                        background: $text-color;
                    }
                }
            }
            @include minsmalldesk{
            &.menu-item-has-children{
                position: relative;
                &>a::after{
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    -moz-osx-font-smoothing: grayscale;
                    -webkit-font-smoothing: antialiased;
                    display: inline-block;
                    font-style: normal;
                    font-variant: normal;
                    text-rendering: auto;
                    line-height: 1;
                    content: "\f107";
                    font-size: 16px;
                    margin: 0 0 0 5px;
                }
                ul{
                    position: absolute;
                    width: 200px;
                    background: #FFF;
                    border: #E0E0E0 1px solid;
                    @include border-radius(10px);
                    z-index: 12;
                    padding: 13px;
                    right: 0;
                    top: 63px;
                    display: none;
                    
                    &:before{
                        position: absolute;
                        border-left: solid 12px transparent; 
                        border-right: solid 12px transparent; 
                        border-bottom: solid 12px #fff; 
                        height: 0; 
                        width: 0;
                        content: '';
                        display: block;
                        top: -12px;
                        right: 17px;
                        z-index: 2;
                    }
                    &:after{
                        position: absolute;
                        border-left: solid 13px transparent; 
                        border-right: solid 13px transparent; 
                        border-bottom: solid 13px #E0E0E0; 
                        height: 0; 
                        width: 0;
                        content: '';
                        display: block;
                        top: -13px;
                        right: 16px;
                        z-index: 1;
                    }
                    li{
                        float: none;
                        padding: 0 0 0;
                        border-bottom: #F2F2F2 1px solid;
                        order: 3;
                        &:last-child{
                            border-bottom: 0;
                        }
                        a{
                            padding: 10px;
                            display: block;
                            font-size: 14px;
                            font-weight: normal;
                            font-family: $base-font;
                            &:hover{
                                background: rgba(48,200,172,.07);
                                color:$text-color;
                            }
                        }
                        &.current-menu-item {
                            a{
                                background: $text-color;
                                color: #FFF;
                            }
                        }
                        
                    }
                    &.show{
                        display: block;
                    }
                }
            }
            }
            @include smalldesk{
                &.menu-item-has-children{
                    ul{
                        padding: 3px 13px 0;
                        li{
                            &:before{
                                content: '-';
                                margin: 0 5px 0 0;
                            }
                        }
                    }
                }
            }
        }
        
        
    }
    @include smalldesk{
        display: block;
        position: absolute;
        background: #FFF;
        top:70px;
        right: 5%;
        z-index: 10;
        @include border-radius(10px);
        border: #e0e0e0 1px solid;
        ul {
            padding: 13px;
            li{
                float: none;
                padding: 5px 0;
                &.starts{
                    margin-top: 10px;
                    a{
                        padding: 13px 15px;
                        font-size: 16px;
                        font-weight: normal;
                    }
                }
            }
        }
    }
    @include smalldesk{
        display: none;
        &.show{
            display: block;
        }
        ul{
            display: flex;
            flex-direction: column;
            li{
                order: 3;
                &:last-child{
                    order: 0;
                }
                &.menu-item-192{
                    order: 1;
                }
            }
        }
    }
}