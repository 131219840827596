// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.btn-primary{
    background: $brand-color;
    font-size:16px;
    font-weight: 600;
    border:$brand-color 1px solid;
    padding: 14px 24px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
    &:hover, &:active, &:focus{
        text-decoration: none;
        background: #4a98ca;
        border:#4a98ca 1px solid;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}