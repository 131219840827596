/* -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
*/
.main-banner{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
    &:before{
        content:'';
        display: block;
        width: 500px;
        height: 500px;
        position: absolute;
        left: 50%;
        top: 40%;
        background: rgba(48,200,172, 0.10);
        @include border-radius(50%);
        margin: -250px 0 0 -250px;
        z-index: 1;
        @include tablet{
            display: none;
        }
    }
    .main-banner-left{
        display: flex;
        width: 50%;
        justify-content: right;
        position: relative;
        z-index: 5;
        @include bigscreen{
            justify-content: left;
        }
        .bannerleft-inner{
            float: right;
            min-width: 651px;
            @include bigscreen{
                padding-left:25px;
                min-width: inherit;
            }
            @include big-tablet{
                margin:0 auto;
                text-align: center;
                padding: 25px 25px 0;
            }
            @include tablet{
                margin-bottom: 15px;
            }
        }
        h1{
            font-size: 64px;
            color: $text-color;
            font-weight: normal;
            font-family: $heading-font;
            margin:0 0 47px;
            line-height: 68px;
            max-width: 600px;
            span{
                color:$text-color;
                font-size: 26px;
                font-weight: normal;
                text-transform: uppercase;
                display: block;
                line-height: 40px;
                @include mobile{
                    font-size: 20px;
                    line-height: 30px;
                }
            }
            @include bigscreen{
                font-size: 55px;
            }
            @include smalldesk{
                font-size: 40px;
                line-height: 50px;
                span{
                    font-size: 20px;
                }
            }
            @include big-tablet{
                margin:0 0 25px;
            }
            @include mobile{
                font-size: 30px;
                line-height: 40px;
            }

        }
        h5{
            max-width: 550px;
            font-size: 32px;
            font-weight: normal;
            color: #28275A;
            margin: 0 0 20px;
            span{
                color: #30C8AC;
            }
        }
        h6{
            max-width: 435px;
            color:#28275a;
            font-size: 20px;
            font-weight: normal;
            margin:10px 0 0
        }
        p{
            max-width: 435px;
            color:#28275a;
            font-size: 20px;
            font-weight: normal;
            margin: 0 0 0;
            span{
                font-size: 24px;
                text-transform: capitalize;
                color: #FFAB40;
                font-weight: 600;
            }
            @include smalldesk{
                text-align: center;
                font-size: 16px;
            }
            @include big-tablet{
                text-align: center;
                font-size: 14px;
                max-width: inherit;
            }
        }
        .solutionbanner{
            max-width: 483px;
            h3{
                color:$text-color;
                font-size: 20px;
                font-weight: 500;
                margin: 0 0 15px;
            }
            ul{
                list-style: none;
                padding: 0 0 0;
                margin: 0 -10px 0;
                li{
                    list-style: none;
                    padding: 0 0 0;
                    margin: 0 10px 0;
                    float: left;
                    a.btn-primary{
                        @include border-radius(10px);
                        -webkit-box-shadow:none;
                        box-shadow:none;
                        background: $orange-color;
                        border:0;
                        span{
                            display: block;
                            text-transform: uppercase;
                            color: #af5b10;
                            font-size: 12px;
                        }
                        &:focus{
                            -webkit-box-shadow:none;
                            box-shadow:none;
                        }
                        margin-bottom: 20px;
                        &:hover{
                            background: #404040;
                        }
                        @include smalldesk{
                            font-size: 14px;
                        }
                        @include tablet{
                            margin-bottom: 20px;
                        }
                        @include mobile{
                            margin-bottom: 10px;
                        }

                    }
                    &:first-child a, &:nth-child(2) a, &:nth-child(3) a{
                        padding: 14px 28px;
                        @include smalldesk{
                            padding: 14px 25px;
                        }
                        @include tablet{
                            padding: 25px 25px;
                        }
                        @include mobile{
                            padding: 14px 25px;
                        }
                    }
                    @include tablet{
                        width: 44%;
                        a{
                            width: 100%;
                            max-width: 250px;
                            padding: 25px 25px;
                            margin-bottom: 10px;
                        }
                        margin: 0 10px;
                    }
                    @include mobile{
                        width: 100%;
                        margin: 0 0;
                    }
                }
                @include big-tablet{
                    margin: 0 auto;
                    display: inline-block;
                    vertical-align: top;
                    li{
                        display: inline-block;
                        vertical-align: top;
                        float: none;
                    }
                }
                
            }
        }
        @include big-tablet{
            width: 100%;
            float: none;
        }
    }
    .main-banner-img{
        float: right;
        width: 50%;
        position: relative;
        z-index: 10;
        img{
            max-width: 100%;
        }
        @include big-tablet{
            width: 100%;
            float: none;
            text-align: center;
        }
    }
    &.medium{
        .main-banner-left {
            h1{
                font-size: 48px;
                max-width: 550px;
                line-height: 60px;
                margin-bottom: 30px;
                @include smalldesk{
                    font-size: 35px;
                    line-height: 46px;
                }
                @include big-tablet{
                    font-size: 25px;
                    line-height:32px;
                    text-align: center;
                    max-width: inherit;
                }
                @include tablet{
                    margin-bottom: 15px;
                }
            }
        }
    }
    @include smalldesk{
        padding: 20px 0;
    }
    @include big-tablet{
        display: block;
    }
    &.signle-banner{
        margin: 60px 0;
        img{
            @include border-radius(10px);
        }
        .date-time{
            background: #FFAB40;
            @include border-radius(10px);
            display: inline-block;
            padding: 6px 10px;
            span{
                vertical-align: top;
                margin: 0 10px 0;
                font-size: 18px;
                color: #FFF;
                i{
                    margin-right: 8px;
                }
            }
            @include tablet{
                margin-bottom: 8px;
            }
        }
        @include tablet{
            margin: 0 0;
        }
    }
}

/* Recognized Section */
.recognized-section{
    background: $text-color;
    color: #fff;
    margin-bottom: 80px;
    .recognized-widget{
        padding: 25px 0;
        overflow: hidden;
        position: relative;
        &:before{
            content:'';
            display: block;
            width: 500px;
            height: 500px;
            position: absolute;
            left: 50%;
            top: 50%;
            background: rgba(48,200,172, 0.10);
            @include border-radius(50%);
            margin: -250px 0 0 -250px;
            z-index: 1;
        }
        &:after{
            content:'';
            display: block;
            width: 400px;
            height: 400px;
            position: absolute;
            left: 50%;
            top: 50%;
            background: rgba(255,255,255, 0.08);
            @include border-radius(50%);
            margin: -200px 0 0 -200px;
            z-index: 2;
        }
        ul{
            list-style: none;
            padding: 0 0 0;
            margin: 0 0 0;
            display: flex;
            flex-wrap: wrap;
            position: relative;
            z-index: 5;
            li{
                list-style: none;
                padding: 0 0 0;
                margin: 0 0 0;
                flex-grow: 1;
                text-align: center;
                font-size: 20px;
                font-weight: 500;
                color: #FFF;
                &:first-child{
                    text-align: left;
                }
                a{
                    display: inline-block;
                    background: rgba(255,171,64,0.7);
                    color:#FFF;
                    text-decoration: none;
                    font-weight: 500;
                    @include transition(0.4s);
                    -webkit-border-radius: 6px;
                    -moz-border-radius: 6px;
                    border-radius: 6px;
                    padding: 8px 25px;
                    font-size: 16px;
                    line-height: 23px;
                    
                    &:hover{
                        text-decoration: none;
                        color: #FFF;
                        text-shadow: 1px 1px 12px rgba(255,255,255,0.24);
                        @include transition(0.4s);
                        background: rgba(255,171,64,1.0);
                    }
                    &.nolink{
                        cursor: default;
                    }
                }
                @include big-tablet{
                    width: 50%;
                    text-align: center;
                    padding: 15px 0;
                    &:first-child{
                        text-align: center;
                    }
                }
                @include tablet{
                    font-size: 16px;
                }
            }
        }
    }
}
/* Recognized Section ends */

/* Solution Section */
.solution-section{
    display: flex;
    align-items: center;
    margin: 0 0 100px;
    .soltion-left{
        img{
            max-width: 100%;
        }
        @include smalldesk{
            width: 100%;
            text-align: center;
            order: 2;
        }
    }
    .solution-right{
        display: flex;
        align-items: center;
        min-width: 547px;
        justify-content: center;
        .solutiondesc{
            max-width: 547px;
        }
        h2{
            margin-bottom: 40px;
            @include smalldesk{
                text-align: center;
            }
        }
        .solutions-list{
            text-align: center;
            background: url(../images/industry-bg.png) no-repeat top;
            .solution-col{
                display: inline-block;
                text-align: left;
                max-width: 380px;
                &:first-child{
                    margin: 46px 0px;
                    @include desktop{
                        margin: 12% 0;
                    }
                    @include smalldesk{ 
                        margin: 12% 0;
                    }
                    @include big-mobile{
                        margin: 10% 0;
                    }
                    @include mobile{
                        margin: 10% 0 7%;
                    }
                }
                &:nth-child(2){
                    margin: 36px 0px;
                    @include desktop{
                        margin: 0 0 12%;
                    }
                    @include big-mobile{
                        margin: 3% 0 11%;
                    }
                    @include mobile{
                        margin: 3% 0 7%;
                    }

                }
                &:last-child{
                    margin: 33px 0px;
                    min-height: 118px;
                    @include desktop{
                        margin: 0 0 10%;
                    }
                    @include smalldesk{
                        margin: 0 0 8%;
                    }
                }
                @include mobile{
                    max-width: 300px;
                    padding-left: 19px;
                }
                p{
                    margin: 0 0 0;
                }
            }
            h3{
                font-weight: normal;
                font-size: 24px;
                color: $text-color;
                margin: 0 0 12px;
                @include mobile{
                    font-size: 14px;
                    margin: 0 0 8px;
                }
            }
            p{
                font-size: 14px;
                font-weight: normal;
                color: #28275a;
                @include mobile{
                    font-size: 11px;
                    margin: 0 0 0;
                }
            }
            @include desktop{
                background-size: 100%;
            }
            @include mobile{
                max-width: 320px;
                margin: 0 auto;
            }
        }
        @include desktop{
            min-width: inherit;
        }
        @include smalldesk{
            width: 100%;
        }
    }
    @include mobile{
        margin-bottom: 35px;
    }
}
/* Solution Section ends */


/* Services Section */
.services-section{
    position: relative;
    margin-bottom: 120px;
    &::before{
        content: '';
        display: block;
        z-index: -1;
        background: $brand-color;
        width: 100%;
        position: absolute;
        right: 0;
        top: 67px;
        height: 193px;
        @include smalldesk{
            top: 0;
        }
    }
    .service-circle{
        display: block;
        z-index: -1;
        background: #FFF;
        width: 50%;
        position: absolute;
        left: 0;
        top: 67px;
        height: 193px;
        @include smalldesk{
            top: 0;
        }
        @include mobile{
            width: 70%;
        }
        &:after{
            content: '';
            display: block;
            z-index: 0;
            background: #FFF;
            width: 293px;
            height: 293px;
            position: absolute;
            right: -45px;
            top: 50%;
            margin-top: -147px;
            @include border-radius(50%);
        }
    }
    .service-heading{
        max-width: 260px;
        padding-top: 90px;
        h2{
            text-transform: capitalize;
            font-size:36px ;
            font-weight: normal;
            color: $text-color;
            margin: 0 0 0;
            span{
                color:$brand-color;
            }
        }

        @include smalldesk{
            padding-top: 28px;
            min-height: 193px;
            margin-bottom: 70px;
        }
    }
    .service-col{
        .service-inner{
            background:#FFFFFF;
            box-shadow: 0px 30px 60px 0px rgba(0,0,0,0.1);
            -webkit-box-shadow: 0px 30px 60px 0px rgba(0,0,0,0.1);
            -moz-box-shadow: 0px 30px 60px 0px rgba(0,0,0,0.1);
            max-width: 405px;
            margin: 0 auto;
            @include border-radius(10px);
            padding: 28px;
            transition: all 300ms;
            margin-top: 5px;
            height: 100%;
            img{
                margin-bottom: 40px;
            }
            .service-description{
                min-height: 160px;
                padding-bottom: 10px;
            }
            .service-descc{
                p{
                    font-size: 16px;
                    margin: 0 0 20px;
                }
                ul{
                    text-align: left;
                    list-style: decimal;
                    li{
                        margin-bottom: 5px;
                        font-size: 15px;
                        color: #28275a;
                    }
                }
            }
            h3{
                margin: 0 0 25px;
                font-size: 28px;
                color: $text-color;
                font-family: $heading-font;
                font-weight: normal;
            }
            p{
                font-size: 14px;
                font-weight: normal;
                color: $text-color;
                margin: 0 0 0;
            }
            .btn-primary{
                display: block;
                font-size: 16px;
                font-weight: 500;
                color: #FFF;
                padding: 20px 24px;
                background: $orange-color;
                border: 0;
                box-shadow: none;
                &:focus, &:hover{
                    box-shadow: none;
                    border: 0;
                    background: #404040;
                }
            }
            &:hover{
                transition: all 300ms;
                margin-top: -5px;
                
            }
        }
        @include smalldesk{
            width: 50%;
        }
        @include mobile{
            width: 100%;
            margin-bottom: 20px;
        }
    }
    .service-colhead{
        @include smalldesk{
            width: 60%;
        }
        @include mobile{
            width: 100%;
        }
    }
    @include tablet{
        margin-bottom: 60px;
    }
}
/* Services Section ends */


/* Opportunities Services */
.opportunities-section{
    .opportunities-content{
        h2{
            font-size: 36px;
            text-transform: capitalize;
            span{
                display: inline-block;
                vertical-align: top;
                font-size: 36px;
            }
            @include desktop{
                max-width:570px;
                margin: 0 auto 10px;
            }
        }
        &>ul{
            padding: 0 0 0;
            margin: 50px 0 0;
            list-style: none;
            display: block;
            max-width: 545px;
            @include transition(0.4s);
            position: relative;
            &>li{
                padding: 0 0 0;
                margin: 0 0 13px;
                list-style: none;
                display: block;
                text-transform: capitalize;
                @include transition(0.4s);
                z-index: 8;
                text-align: center;
                
                &>a{
                    text-transform: capitalize;
                    font-weight: normal;
                    font-family: $heading-font;
                    color: $text-color;
                    font-size: 20px;
                    width: 350px;
                    display: inline-block;
                    vertical-align: top;
                    height: 83px;
                    padding:27px 22px;
                    line-height: 25px;
                    margin: 0 auto;
                    border: $text-color 1px solid;
                    @include border-radius(10px);
                    @include transition(0.4s);
                    box-shadow: 0 30px 60px 0 rgba(0,0,0,.1);
                    -webkit-box-shadow: 0 30px 60px 0 rgba(0,0,0,.1);
                    -moz-box-shadow: 0 30px 60px 0 rgba(0,0,0,.1);
                    text-align: left;
                    &:after{
                        content: '';
                        display: inline-block;
                        vertical-align: top;
                        background: url(../images/arrow-right.png) no-repeat;
                        width: 25px;
                        height: 25px;
                        float: right;
                    }
                    &:hover{
                        text-decoration: none;
                        background: $text-color;
                        color: #FFF;
                        @include transition(0.4s);
                    }
                    &:focus, &:active{
                        text-decoration: none;
                    }
                    @include mobile{
                        width: 100%;
                    }
                }
                // &:first-child{
                //     padding-left:30%;
                //     a{
                //         margin: 0 0 0;
                //         text-align: left;
                //     }
                //     @include desktop{
                //         padding-left:0;
                //         text-align: center;
                //         a{
                //             text-align: left;
                //         }
                //     }
                // }
                // &:nth-child(2){
                //     padding-left:10%;
                //     text-align: left;
                //     a{
                //         margin: 0 0 0;
                //     }
                //     @include desktop{
                //         padding-left:0;
                //         text-align: center;
                //         a{
                //             text-align: left;
                //         }
                //     }
                // }
                // &:nth-child(3){
                //     padding-left:30%;
                //     a{
                //         margin: 0 0 0;
                //         text-align: left;
                //     }
                //     @include desktop{
                //         padding-left:0;
                //         text-align: center;
                //         a{
                //             text-align: left;
                //         }
                //     }
                // }
                // &:last-child{
                //     padding-left:10%;
                //     text-align: left;
                //     a{
                //         margin: 0 0 0;
                //     }
                //     @include desktop{
                //         padding-left:0;
                //         text-align: center;
                //         a{
                //             text-align: left;
                //         }
                //     }
                // }
                &:before, &:after{
                    clear: both;
                    content: '';
                    display: table;
                }
                &.show{
                    .opportunity-overlay{
                        display: block;
                    }
                    &>a{
                        text-decoration: none;
                        background: $text-color;
                        color: #FFF;
                        @include transition(0.4s);
                    }
                }
                .opportunity-overlay{
                    position: absolute;
                    left: 85%;
                    top: 0;
                    width: 360px;
                    height: 100%;
                    background: #FFF;
                    border: #ffab40 1px solid;
                    box-shadow:0 -14px 25px 0 rgba(0,0,0,.1);
                    -webkit-box-shadow: 0 -14px 25px 0 rgba(0,0,0,.1);
                    -moz-box-shadow:0 -14px 25px 0 rgba(0,0,0,.1);
                    z-index: 0;
                    display: none;
                    text-align: left;
                    padding: 22px 23px;
                    @include desktop {
                        position: relative;
                        left: inherit;
                        top: inherit;
                        width: 100%;
                        height: auto;
                        padding: 30px 23px;
                        margin-top: 15px;
                        margin-bottom: 5px;
                    }
                    &.show{
                        display: block;
                    }
                    p{
                        font-size: 20px;
                        font-weight: 600;
                        color: $text-color;
                        line-height: 24px;
                        margin-bottom: 20px;
                    }
                    ul{
                        list-style: none;
                        padding: 0 0 0;
                        margin: 0 0 0;
                        counter-reset: section;
                        li{
                            list-style: none;
                            padding: 0 0 0 40px;
                            margin: 5px 0 15px;
                            font-size: 16px;
                            font-weight: normal;
                            color: $text-color;
                            position: relative;
                            min-height: 30px;
                            &:before{
                                counter-increment: section;
                                content: counter(section);
                                display: flex;
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 30px;
                                height: 30px;
                                justify-content: center;
                                align-items: center;
                                background:#ffab40;
                                color: #FFF;
                                font-size: 14px;
                                font-weight: 600;
                                @include border-radius(50%);
                            }
                        }
                    }
                }
            }
            &:hover{
                li{
                    padding: 0 0 0;
                    // &:last-child, &:nth-child(2), &:nth-child(3), &:first-child{
                    //     padding: 0 0 0;
                    //     text-align: center;
                    //     a{
                    //         margin: 0 auto;
                    //         float: none;
                    //         text-align: left;
                    //     }
                    // }
                }
            }
            @include desktop{
                margin: 50px auto 70px;
                text-align: left;
            }
        }
        @include desktop{
            width:100%;
            order: 2;
            text-align: center;
        }
    }
    .opportunities-img{
        img{
            max-width: 100%;
        }
        @include desktop{
            width:100%;
            text-align: center;
        }
    }
}

/* Opportunities Services ends */ 

/* Latest Projects */
.latestprojects-sec{
    padding-left: 75px;
    padding-bottom: 70px;
    padding-top: 70px;
    background: #F4FCFA;
    h2{
        text-align: center;
        font-size: 40px;
        margin: 0 0 30px;
        span{
            display: inline-block;
            vertical-align: top;
            font-size: 40px;
        }
    }
    .item{
        width:526px;
        max-width: 526px;
        @include big-tablet{
            width: 2800px;
            max-width: 280px;
        }
        @include mobile{
            max-width: 290px;
        }
    }
    .project-colum{
        img{
            max-width: 100%;
            height: auto;
        }
        .pro-desc{
            padding: 20px 30px;
            border:rgba(40,39,90,0.2) 1px solid;
            border-top: 0;
            -webkit-border-bottom-right-radius: 10px;
            -webkit-border-bottom-left-radius: 10px;
            -moz-border-radius-bottomright: 10px;
            -moz-border-radius-bottomleft: 10px;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            background: #FFF;
            @include mobile{
                padding: 20px 20px;
            }
        }
        -webkit-border-bottom-right-radius: 10px;
        -webkit-border-bottom-left-radius: 10px;
        -moz-border-radius-bottomright: 10px;
        -moz-border-radius-bottomleft: 10px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        h2{
            text-align: left;
            font-size: 24px;
            font-weight: normal;
            font-family: $heading-font;
            margin-bottom: 25px;
            color: $text-color;
            clear: both;
            a{
                color: $text-color;
            }
            @include big-tablet{
                margin-bottom: 12px;
            }
        }
        p{
            margin: 0 0 0;
            font-size: 14px;
            font-weight: normal;
            color: $text-color;
            float: left;
            max-width: 350px;
        }
        .readmore{
            float: right;
            width: 40px;
            height: 40px;
            @include border-radius(50%);
            background: $orange-color;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFF;
            text-decoration: none;
            @include transition(0.4s);
            &:hover, &:focus{
                text-decoration: none;
                background: $text-color;
                @include transition(0.4s);
            }
        }
        .project-date{
            float: none;
            font-size: 14px;
            color: #28275A;
        }
        .project-description{
            .shortcont{
                min-height: 74px;
            }
        }
    }
    .owl-dots{
        margin-top: 30px;
        text-align: center;
        .owl-dot{
            margin: 0 4px;
            span{
                display: block;
                width: 10px;
                height: 10px;
                background: $brand-color;
                @include border-radius(50%);
            }
            &.active{
                span{
                    background: $orange-color;
                }
            }
        }
    }
    @include big-tablet{
        padding-left: 25px;
    }
    &.blog-listing{
        padding-top: 100px;
        .project-colum{
            margin: 0 0 30px;
            img{
                max-width: 100%;
            }
        }
    }
}
/* Latest Projects ends */

.partners-wrap{
    -webkit-box-shadow: 0px 4px 20px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 4px 20px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 4px 20px 0px rgba(0,0,0,0.25);
    min-height: 100px;
    margin: 60px 0 40px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
    .partners-content{
        background: #30C8AC;
        padding:22px;
        -webkit-border-top-left-radius: 7px;
        -webkit-border-top-right-radius: 7px;
        -moz-border-radius-topleft: 7px;
        -moz-border-radius-topright: 7px;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        h3{
            font-size: 20px;
            font-weight: 500;
            color:$text-color;
            margin: 0 0 9px;
        }
        p{
            font-size: 14px;
            font-weight: normal;
            color: #FFF;
            margin: 0 0 0;
        }
    }
    .partners-images{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .part-img{
            align-items: center;
            justify-content: center;
            padding: 20px;
            width: 25%;
            text-align: center;
        }
    }
}

.customform{
    textarea{
        width:100%;
        border: 2px solid #000;
    }
}