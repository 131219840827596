/* -----------------------------------------------------------------------------
// This file contains very basic styles.
// ----------------------------------------------------------------------------- */

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: $brand-color;
  text-decoration: none;

  @include on-event {
    color: $text-color;
    text-decoration: underline;
  }
}
div:after, div:before {
  content: "";
  display: table;
  clear: both;
}
.wrapper{
  max-width: 1300px;
  margin:0 auto 0;
  @include big-mac{
    max-width: 90%;
  }
  @include ttf-tw{
    max-width: 90%;
  }
}
h1{
  font-size: 39px;
  color: $text-color;
  font-family: $heading-font;
  font-weight: normal;
  margin: 0 0 15px;
  span{
    color: $brand-color;
    font-weight: normal;
  }
}
h2{
  font-size: 36px;
  color: $text-color;
  font-family: $heading-font;
  font-weight: normal;
  margin: 0 0 15px;
  span{
    color: $brand-color;
    font-size: 40px;
    font-weight: normal;
    display: block;
  }
}
h3{
  font-size: 32px;
  color: $text-color;
  font-family: $heading-font;
  font-weight: normal;
  margin: 0 0 15px;
  span{
    color: $brand-color;
    font-weight: normal;
  }
}
h4{
  font-size: 28px;
  color: $text-color;
  font-family: $heading-font;
  font-weight: normal;
  margin: 0 0 15px;
  span{
    color: $brand-color;
    font-weight: normal;
  }
}
h5{
  font-size: 24px;
  color: $text-color;
  font-family: $heading-font;
  font-weight: normal;
  margin: 0 0 15px;
  span{
    color: $brand-color;
    font-weight: normal;
  }
}
h6{
  font-size: 20px;
  color: $text-color;
  font-family: $heading-font;
  font-weight: normal;
  margin: 0 0 15px;
  span{
    color: $brand-color;
    font-weight: normal;
  }
}
ol, ul{
  li{
    font-size: 18px;
    color: #707070;
  }
}

.heading-bar{
  background: $brand-color;
  min-height: 100px;
  position: relative;
  margin: 0 0 90px;
  &:before{
    background: #FFF;
    content: '';
    display: block;
    position: absolute;
    width: 33%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
  }
  .headingcol{
    position: relative;
    z-index: 5;
    display: flex;
    align-items: center;
    min-height:100px;
    background: #FFF;
    h2{
      margin: 0 0 0;
      color:#ffab40 ;
      @include tablet{
       font-size: 26px;
      }
    }
    &:after{
      content: '';
      display: block;
      right: -150px;
      width: 293px;
      height: 293px;
      top: 50%;
      margin-top: -146px;
      background: #FFF;
      position: absolute;
      @include border-radius(50%);
      z-index: -1;
      @include tablet{
        right: -100px;
      }
      @include mobile{
        right: -40px;
      }
    }
    @include tablet{
      max-width: 80%;
    }
  }
  &.blogheadingbar{
    margin-bottom: 0;
    overflow: hidden;
    &::before{
      background: #f4fcfa;
    }
    .headingcol{
      background: #f4fcfa;
      &::after{
        background: #f4fcfa;
      }
    }
  }
}
.mobilemenu{
  float: right;
  margin-top: 3px;
  display: none;
  i{
    font-size: 34px;
    vertical-align: top;
  }
  @include smalldesk{
    display: block;
}
}

.contact-formx{
  padding: 80px 0 0;
  max-width:750px; 
  margin: 0 auto 80px;

  .form-group{
    margin: 0 0 20px;
    label{
      color: #28275A;
      font-size: 14px;
      font-weight: normal;
      margin: 0 0 4px;
      &.req{
        &:after{
          content: "*";
          color: red;
          padding-left: 0.2em;
        }
      }
    }
    .radio-style{
      display: block;
      span{
        margin-left: 0;
        margin-right: 10px;
      }
    }
    .wpcf7-not-valid-tip{
      position: absolute;
      font-size: 14px;
    }
    &.submitbtn{
      text-align: center;
      padding-top: 25px;
    }
  }
  .form-control{
    padding: 13px 15px;
    border:#BDBDBD 1px solid;
    color: #28275A;
    font-size: 14px;
    font-weight: normal;
  }
  .wpcf7-textarea{
    height: 120px;
  }
  .form-select{
    padding: 13px 2.25rem 13px 15px;
    border:#BDBDBD 1px solid;
    color: #28275A;
    font-size: 14px;
    font-weight: normal;
  }
  textarea{
    resize: none;
  }
  .btn-primary{
    font-size: 14px;
    color: #FFFFFF;
    font-weight: 600;
    background: $orange-color;
    border: 0;
    padding: 15px 25px;
    text-transform: uppercase;
    line-height: 22px;
    min-width: 150px;
    &:hover, &:focus{
      border: 0;
      box-shadow: none;
      background: #28275a;
    }
  }
}

.single-content{
  padding-top: 50px;
  margin-bottom: 60px;
  p{
    font-size: 18px;
    color: #707070;
    @include big-tablet{
      font-size: 16px;
    }
    @include tablet{
      font-size: 14px;
    }
  }
  @include tablet{
    padding-top: 30px;
    margin-bottom: 40px;
  }
}

.sharediv{
  float:right;
}
.clear{
  &::before, &::after{
    display: table;
    content: '';
    clear: both;
  }
}
.page_content{
  padding: 30px 0 60px;
}