/* -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// ----------------------------------------------------------------------------- */
.bottom-banner{
    background: $brand-color;
    position: relative;
    &:after{
        content: '';
        display: block;
        width: 37%;
        background: #FFF;
        height: 100%;
        position: absolute;
        right: 0;
        top:0;
        z-index: 1;
        @include tablet{
            width: 60%;
        }
    }
    .bottom-img{
        position: relative;
        z-index: 5;
        img{
            margin-top: -17px;
            max-width: 100%;
        }
        @include smalldesk{
            width: 50%;
        }
        @include big-tablet{
            width:60%;
        }
        @include tablet{
            width: 100%;
        }
    }
    .bottom-content{
        display: flex;
        align-items: center;
        h3{
            max-width: 553px;
            font-size: 36px;
            font-weight: normal;
            font-family: $heading-font;
            color: #FFFFFF;
            margin-bottom: 25px;
            @include smalldesk{
                font-size: 30px;
            }
            @include big-tablet{
                font-size: 24px;
            }
            @include tablet{
                color:$text-color;
                position: relative;
                z-index: 5;
            }
        }
        .btn-primary{
            background: $text-color;
            border: 0;
            box-shadow: none;
            &:hover, &:focus{
                background: $orange-color;
                border: 0;
                box-shadow: none;
            }
        }
        @include smalldesk{
            width: 50%;
            padding: 25px 0;
        }
        @include big-tablet{
            width: 40%;
        }
        @include tablet{
            width: 100%;
            padding: 30px 15px;
            position: relative;
            z-index: 5;
        }
    }
}
footer{
    position: relative;
    overflow: hidden;
    padding: 80px 0 0;
    @include tablet{
        padding: 40px 0 0;
    }
    &:before{
        content:'';
        display: block;
        width: 350px;
        height: 350px;
        position: absolute;
        left: -168px;
        top: -144px;
        background: rgba(48,200,172, 0.10);
        @include border-radius(50%);
    }
    &:after{
        content:'';
        display: block;
        width: 350px;
        height: 350px;
        position: absolute;
        right: -168px;
        bottom: -144px;
        background: rgba(48,200,172, 0.10);
        @include border-radius(50%);
    }
    .footer-top{
        margin-bottom: 60px;
        @include tablet{
            text-align: center;
        }
        img{
            float: left;
            max-width: 250px;
            margin-top: 6px;
            @include tablet{
                float: none;
                margin: 0 0 15px;
            }
        }
        .subscribe-footer{
            float: right;
            width: 45%;
            @include tablet{
                float: none;
                width: 60%;
                margin: 0 auto;
                text-align: left;
            }
            @include tablet{
                width: 100%;
            }
            .form-control{
                margin: 0 0 0 0;
                @include border-radius(10px);
                background: rgba(40,39,90,0.03);
                &:focus{
                    box-shadow: none;
                    outline: 0;
                }
            }
            .btn-primary{
                background: $orange-color;
                border: 0;
                font-size: 16px;
                box-shadow: none;
                padding: 18px 30px;
                -webkit-border-top-left-radius: 0;
                -webkit-border-bottom-left-radius: 0;
                -moz-border-radius-topleft: 0;
                -moz-border-radius-bottomleft: 0;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                &:focus, &:hover{
                    box-shadow: none;
                    background: #404040;
                }
            }
        }
    }
    .footer-bottom{
        ul{
            padding: 0 0 0;
            margin: 0 0 0;
            list-style: none;
            li{
                padding: 0 0 0;
                margin: 0 0 0;
                list-style: none;
                a{
                    font-size: 15px;
                    color: $text-color;
                    font-weight: normal;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
       .copyright {
            border-top: #28275A 1px solid;
            margin-top: 30px;
            padding: 15px 0;
        p{
            margin: 0 0 0;
            font-size: 14px;
            text-align: right;
            @include big-tablet{
                float: none;
                text-align: center;
            }
        }
       }
       .footercol{
        @include tablet{
            width: 50%;
        } 
       }
    }
} 

.subscribe-footer #customForm input[type=text]{ 
    border: #ced4da 1px solid !important;  
    -webkit-border-top-left-radius: 15px !important;
-webkit-border-bottom-left-radius: 15px !important;
-moz-border-radius-topleft: 15px !important;
-moz-border-radius-bottomleft: 15px !important;
border-top-left-radius: 15px !important;
border-bottom-left-radius: 15px !important;
}
.subscribe-footer #customForm input[type=button]{ 
    font-size: 16px !important;
    font-family: $base-font !important;
    font-weight: 600 !important;
    -webkit-border-top-right-radius: 15px !important;
-webkit-border-bottom-right-radius: 15px !important;
-moz-border-radius-topright: 15px !important;
-moz-border-radius-bottomright: 15px !important;
border-top-right-radius: 15px !important;
border-bottom-right-radius: 15px !important;
}
.subscribe-footer #customForm #SIGNUP_HEADING{
    padding: 0 0 !important;
}
.subscribe-footer #customForm #SIGNUP_FLD{
    height: auto !important;
    width: 100% !important;
    max-width: 375px !important;
    margin: 0 0 !important;
}
.subscribe-footer #customForm #zcampaignOptinForm div:nth-child(2).SIGNUP_FLD{
    width:79% !important;
    margin: 0 0 !important;
    float: left;
}
.subscribe-footer #customForm #zcampaignOptinForm div:nth-child(3).SIGNUP_FLD{
    width:21% !important;
    margin: 0 0 !important;
}
.subscribe-footer #customForm #zcampaignOptinForm div:nth-child(3).SIGNUP_FLD input[type=button]{
    margin: 0 0 !important;
}
.subscribe-footer #customForm .quick_form_9_css{
width: 100% !important;
}
.subscribe-footer #customForm .quick_form_9_css{
    height: auto !important;
}
.subscribe-footer #customForm .quick_form_9_css #zcampaignOptinForm{
    padding: 0 0 !important;
}

@include smalldesk{
    .subscribe-footer #customForm #zcampaignOptinForm div:nth-child(2).SIGNUP_FLD {
        width: 72%!important;
    }
    .subscribe-footer #customForm #zcampaignOptinForm div:nth-child(3).SIGNUP_FLD{
        width:28% !important;
    }
}
@include small-tablet{
    .subscribe-footer #customForm input[type=text]{ 

    -webkit-border-top-right-radius: 0 !important;
    -webkit-border-bottom-right-radius: 0 !important;
    -moz-border-radius-topright: 0 !important;
    -moz-border-radius-bottomright: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    }
    .subscribe-footer #customForm input[type=button]{ 


    -webkit-border-top-left-radius: 0 !important;
    -webkit-border-bottom-left-radius: 0 !important;
    -moz-border-radius-topleft: 0 !important;
    -moz-border-radius-bottomleft: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    }
}