/* -----------------------------------------------------------------------------
// This file contains styles that are specific to the Why Journey page.
// -----------------------------------------------------------------------------
*/


.swapcolums{
    margin-bottom: 60px;
    .info-desc{
        display: flex;
        align-items: center;
        
        .desc-inner{
            max-width: 570px;
        }
    }
    .info-img{
        img{
            max-width: 100%;
        }
    }
    h2{
        font-size: 40px;
        font-family: $heading-font;
        color: $text-color;
        margin: 0 0 25px;
    }
    p{
        font-size: 18px;
        font-family: $base-font;
        color: $text-color;
        line-height: 30px;
        margin: 0 0 0;
    }
    .information-col{
        @include ipad{
            padding: 25px 0;
            h2{
                font-size: 28px;
                margin-bottom: 15px;
            }
            p{
                font-size: 16px;
            }
        }
        @include tablet{
            padding: 35px 0;
            h2{
                font-size: 22px;
                margin-bottom: 15px;
            }
            p{
                font-size: 14px;
                line-height: 23px;
            }
            .desc-inner{
                text-align: center;
                float: none;
                margin: 0 auto 30px;
                max-width: 90%;
            }
            .info-img{
                text-align: center;
            }
        }
    }
    .information-col.swap{
        background: rgba(48,200,172,.05);
        .info-desc{
            order: 2;
            text-align: right;
            justify-content: right;
        }
        @include tablet{
            .info-img{
                margin-bottom: 30px;
            }
        }
        
    }
}



.features-section{
    margin-bottom: 90px;
    padding-top: 60px;
    h2{
        text-align: center;
        margin-bottom: 50px;
        @include tablet{
            font-size: 33px;
            span{
                font-size: 33px;
            }
        }
        @include mobile{
            font-size: 26px;
            span{
                font-size: 26px;
            }
        }
    }
    .features-table{
        border:$text-color 1px solid;
        @include border-radius(10px);
        max-width: 1080px;
        margin: 0 auto;;
        @include ipad{
            width: 100%;
        }
        table{
            margin:0 0 0;
            @include ipad{
                width: 100%;
                thead{
                    display: none;
                }
                tbody{
                    display: block;
                    tr{
                        display: block;
                        td, th{
                            display: block;
                            padding: 10px 20px;
                            text-align: left;
                            clear: both;
                            overflow: hidden;
                            line-height: 43px;
                            p{
                                margin: 0;
                                font-size: 16px;
                                font-weight: 600;
                            }
                            .tick{
                                float: right;
                            }
                        }
                        th{
                            font-weight: 600;
                        }
                        td:nth-child(2):before{
                            content:'Individual';
                            text-align: left;
                        }
                        td:nth-child(3):before{
                            content:'Schools & Organizations (Tier 1)';
                        }
                        td:nth-child(4):before{
                            content:'Schools & Organizations (Tier 2)';
                        }
                        .btn-primary{
                            float: right;
                        }
                    }
                }
            }
        } 
        thead{
            th{
                font-size: 26px;
                font-weight: normal;
                font-family: $heading-font;
                line-height: 34px;
                text-align: center;
                vertical-align: middle;
                padding: 15px 15px;
                box-shadow: none;
                border-bottom: $text-color 1px solid;
                
                span{
                    display: block;
                    font-size: 14px;
                    font-weight: normal;
                    font-family: $base-font;
                }
                @include ipad{
                    font-size: 20px;
                    line-height: 26px;
                }
                @include tablet{
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        }
        tbody{
            border-top: 0;
            th{
                padding: 20px 30px;
            }
            td{
                padding: 20px 20px;
                text-align: center;
            }
            th, td{
                font-size: 20px;
                font-weight: normal;
                font-family: $base-font;
                vertical-align: middle;
                span{
                    color: #979797;
                }
                strong{
                    font-weight: 600;
                }
                .tick{
                    width: 43px;
                    height: 43px;
                    background: rgba(255,171,64,0.2);
                    @include border-radius(50%);
                    margin: 0 0 0;
                    display: inline-block;
                    vertical-align: top;
                    position: relative;
                    &:before{
                        width: 18px;
                        height: 18px;
                        display: block;
                        content: '';
                        background:#FFAB40;
                        @include border-radius(50%);
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        margin-top: -9px;
                        margin-left: -9px;
                    }
                }
                @include ipad{
                    font-size: 16px;
                }
                @include tablet{
                    font-size: 14px;
                }
            }
            tr:nth-of-type(odd)>*{
                background: rgba(48,200,172,0.02);
            }
            .btn-primary{
                background: $orange-color;
                color: #FFF;
                font-size: 18px;
                border: 0;
                box-shadow: none;
                padding: 15px 35px;
                &:focus{
                    box-shadow: none;
                    border: 0;
                }
                &:hover{
                    background: $text-color;
                }
                @include tablet{
                    font-size: 14px;
                }
            }
        }
    }
}