/* -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// ----------------------------------------------------------------------------- */

/// Event wrapper */
/// @author Harry Roberts */
/// @param {Bool} $self [false] - Whether or not to include current selector */
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts */
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly */
/// @author Kitty Giraudel */
/// @param {String} $context */
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}


/** Media Query Mixins */
@mixin phone-only {
  @media (max-width: 575px) { @content; }
}

@mixin phone-portrait-up {
  @media (min-width: 575px) { @content; }
}

@mixin tablet-portrait-up {
  @media (min-width: 992px) { @content; }
}

@mixin tablet-landscape-up {
  @media (min-width: 1024px) { @content; }
}

@mixin minsmalldesk {
  @media (min-width: 1190px) { @content; }
}

@mixin bigscreen {
  @media (max-width: 1345px) { @content; }
}
@mixin desktop {
  @media (max-width: 1280px) { @content; }
}
@mixin smalldesk {
  @media (max-width: 1190px) { @content; }
}
@mixin smaldesk {
  @media (max-width: 1100px) { @content; }
}
@mixin ipad {
  @media (max-width: 991px) { @content; }
}
@mixin big-tablet {
  @media (max-width: 850px) { @content; }
}
@mixin tablet {
  @media (max-width: 768px) { @content; }
}
@mixin small-tablet {
  @media (max-width: 600px) { @content; }
}
@mixin big-mobile {
  @media (max-width: 580px) { @content; }
}
@mixin mobile {
  @media (max-width: 560px) { @content; }
}

@mixin until-desktop-md {
  @media (max-width: 1440px) { @content; }
}
@mixin big-mac {
  @media (max-width: 1340px) { @content; }
}
@mixin until-desktop {
  @media (max-width: 992px) { @content; }
}

@mixin until-desktop-up {
  @media (max-width: 1302px) { @content; }
}
@mixin until-mob {
  @media (max-width: 480px) { @content; }
}

@mixin until-tiny {
  @media (max-width: 375px) { @content; }
}

@mixin ttf-tw {
  @media (max-width: 1230px) { @content; }
}


/** Placeholder Mixin */
@mixin placeholder {
  @include optional-at-root("::-webkit-input-placeholder") {
    @content;
  }

  @include optional-at-root(":-moz-placeholder") {
    @content;
  }

  @include optional-at-root("::-moz-placeholder") {
    @content;
  }

  @include optional-at-root(":-ms-input-placeholder") {
    @content;
  }
}

/* Border Raius Mixin*/
@mixin border-radius($pixel) {
  -webkit-border-radius: $pixel;
  -moz-border-radius: $pixel;
  border-radius: $pixel;
}


/* Transition Mixin*/
@mixin transition($x) {
  -webkit-transition: $x;
  -moz-transition: $x;
  -ms-transition: $x;
  -o-transition: $x;
  transition: $x;
}