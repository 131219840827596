/* -----------------------------------------------------------------------------
// This file contains styles that are specific to the Why Journey page.
// -----------------------------------------------------------------------------
*/

.mantechpage{
    @include tablet {
        .btn-primary{
            font-size: 12px;
        }
    }
    .owl-carousel{
        .owl-nav{
            button.owl-prev, button.owl-next{
                position: absolute;
                top: 50%;
                margin-top:-50px;
                width: 46px;
                height: 46px;
                display: block;
                span{
                    display: none;
                }
            }
            button.owl-prev{
                left: -56px;
                background: url(../images/mantech/prev-icon.png) no-repeat;
                background-size: 100%;
            }
            button.owl-next{
                background: url(../images/mantech/next-icon.png) no-repeat;
                right: -56px;
                background-size: 100%;
            }
        }
        .owl-dots{
            margin: 45px 0 0;
            text-align: center;
            .owl-dot{
                margin: 0 5px;
                display: inline-block;
                vertical-align: top;
                span{
                    display: block;
                    width: 12px;
                    height: 12px;
                    background: #D7D7D7;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    border-radius: 50%;
                    margin-top: 3px;
                }
                &.active{
                    span{
                        background: #FFAB40;
                        width: 18px;
                        height: 18px;
                        margin-top: 0;
                    }
                }
            }
        }
    }
    a.btn-primary{
        &:hover, &:focus{
            outline: 0;
            box-shadow: none;
        }
        &:hover, &:focus{
            background: #ffab40;
        }
    }
    @include until-desktop-md{
        .owl-carousel{
            .owl-nav{
                button.owl-prev{
                    left: 3px;
                }
                button.owl-next{
                    right: 3px;
                }
            }
        }
    }
}
.mantech-banner{
    .mantech-video{
        iframe{
            height: auto;
            aspect-ratio: 14/6;
            width: 100%;
            vertical-align: top;
        }
    }
}
.career-post{
    padding: 80px 0;
    @include big-mobile {
        padding: 40px 0;
    }
    h2{
        font-size: 48px;
        color: #28275A;
        font-weight: 600;
        line-height: 55px;
        margin: 0 0 50px;
        font-family: $base-font;
        text-align: center;
        @include ipad {
            font-size: 40px;
            line-height: 47px;
            margin: 0 0 30px;
        }
        @include tablet {
            font-size: 28px;
            line-height: 37px;
            margin: 0 0 25px;
        }
        span{
            display: inline-block;
            vertical-align: top;
            font-size: 48px;
            font-weight: 600;
            @include ipad {
                font-size: 40px;
            }
            @include tablet {
                font-size: 28px;
            }
        }
    }
    .career-posts {

        &:before,
        &:after {
            content: none;
        }
        @include ipad {
            --COLUMNS: 3;
        }
        @include tablet {
            --COLUMNS: 2;
        }
        @include big-mobile {
            --COLUMNS: 1;
        }
        &.mantech-posts{
            display: flex;
            
            .item{
                width: 25%;
                padding: 0 10px;
                
            }
            @include ipad{
               display: block;
               &:after, &:before{
                clear: both;
                content: '';
                display: table;
               }
               .item{
                float: left;
                width: 33.3%;
                margin-bottom: 20px;
                .postdesc{
                    min-height: 285px;
                }
               }
            }
            @include tablet{
                .item{
                    width: 50%;
                    .postdesc{
                        min-height: 260px;
                    }
                }
            }
            @include big-mobile{
                .item{
                    width: 100%;
                    margin-bottom: 30px;
                    .postdesc{
                        min-height: auto;
                    }
                }
            }

        }
    }
    .careerpost{
        .postimg{
            margin: 0 0 24px;
            img{
                width: 100%;
            }
        }
        .postdesc{
            padding: 0 40px 0 0;
            a {
                &:hover {
                    text-decoration: none;
                    h5 {
                        border-bottom: 2px solid #ffab40;
                    }
                }
                h5 {
                    font-size: 18px;
                    font-family: 'Poppins',sans-serif;
                    font-weight: 600;
                    line-height: 27px;
                    margin-bottom: 12px;
                    width: fit-content;
                    border-bottom: 2px solid transparent;
                    span {
                        font-weight: 600;
                    }
                }
                &.btn {
                    font-size: 14px;
                    padding: 8px 16px;
                    border: none;
                    border-radius: 5px;
                }
            }
            p{
                color: #28275A;
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 24px;
                min-height: 106px;
            }
        }
    }
}

.features-mantech{
    background: #28275A url(../images/mantech/bg-widget.png) no-repeat center;
    min-height: 400px;
    border-bottom: 3px solid #FFAB40;
    padding: 80px 0 80px;
    h2{
        text-align: center;
        font-size: 45px;
        color: #FBFBFB;
        font-family: $base-font;
        font-weight: 500;
        margin: 0 0 50px;
        @include ipad {
            font-size: 38px;
            margin: 0 0 30px;
        }
        @include tablet {
            font-size: 28px;
            margin: 0 0 25px;
        }
        span{
            display: inline-block;
            background: #3D3C70;
            color: #FFAB40;
            -webkit-border-radius: 63px;
            -moz-border-radius: 63px;
            border-radius: 63px;
            padding: 3.5px 14px;
            font-size: 21px;
            font-weight: 500;
            line-height: 32px;
            margin-bottom: 50px;
            @include ipad {
                font-size: 18px;
                margin: 0 0 8px;
            }
            @include tablet {
                font-size: 16px;
                margin: 0 0 8px;
            }
        }
    }
    .features-row{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 24px;
        @include big-mobile {
            flex-direction: column;
        }
        .features-col {
            width: 100%;
            height: 291px;
            max-width: inherit;
            img{
                max-width: 72px;
            }
            @include ipad {
                max-width: unset;
            }
            @include big-mobile {
                width: 100%;
                height: 100%;
            }
        }
        .feature-colum{
            background: #FFFFFF;
            box-shadow:0 -2px 25px 0 rgba(0,0,0,.1);
            -webkit-box-shadow:0 -2px 25px 0 rgba(0,0,0,.1);
            -moz-box-shadow: 0 -2px 25px 0 rgba(0,0,0,.1);
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            border-radius: 8px;
            padding: 40px 13px;
            text-align: center;
            height: 100%;
            img{
                margin: 0 auto 25px;
            }
            p{
                font-size: 16px;
                color: #28275A;
                font-weight: normal;
                margin: 0 0 0;
                span{
                    font-weight: bold;
                }
            }
        }
        @include smalldesk{
            .col-md-3{
                width: 32%;
                margin-bottom: 30px;
                display: inline-block;
                vertical-align: top;
                @include big-tablet{
                    width:49%;
                }
                @include small-tablet{
                    width:100%;
                }
            }
        }
    }
    &.feature-div{
        background: #FFF;
        h2{
            span{
                background: #F6EEE3;
                color: #FFAB40;
            }
        }
        .modal-dialog{
            max-width: 700px;
            .modal-header{
                background: #28275A;
                h5{
                    color: #FFF;
                    font-family: $base-font;
                }
                .btn-close{
                    color: #FFF;
                    &:hover, &:focus{
                        box-shadow: none;
                        outline: 0;
                    }
                }
            }
            .modal-body{
                text-align: center;
                padding:30px ;
                img{
                    margin: 0 0 20px;
                }
            }
        }

        .features-row {
            gap: 20px;
            .features-col{

                height: 362px; 
                @include ipad {

                    max-width: unset;
                }
                @include big-mobile {
                    width: 100%;
                    height: 100%;
                }
            }
            .feature-colum {
                background: #F7F8F9;
                box-shadow: 0 0.72px 1.44px 0 rgba(85, 105, 135, 0.1);
                -webkit-box-shadow: 0 0.72px 1.44px 0 rgba(85, 105, 135, 0.1);
                -moz-box-shadow: 0 0.72px 1.44px 0 rgba(85, 105, 135, 0.1);
                p {
                    font-weight: 500;
                    span {
                        color: #30C8AC;
                    }
                }
                a {
                    color: #30C8AC;
                    font-weight: 600;
                }
            }
        }
    }
    &.feature-divTwo {
        background: #F7F8F9;
        border: none;
        h2 {
            color: #28275A;
            font-size: 48px;
            font-weight: 600;
            margin-bottom: 70px;
            bdi {
                color: #30C8AC;
            }
            @include ipad {
                font-size: 38px;
                margin: 0 0 30px;
            }
            @include tablet {
                font-size: 28px;
                margin: 0 0 25px;
            }
        }
        .features-row {
            gap: 20px;
            .features-col {
                width: calc(25% - 40px);
                max-width: 359px;
                min-height: 395px;
                @include smalldesk {
                    width: 47%;
                    max-width: unset;
                    height: 100%;
                }
                @include big-mobile {
                    width: 100%;
                }
                
            }
            .feature-colum {
                box-shadow: 0 0.72px 1.44px 0 rgba(85, 105, 135, 0.1);
                -webkit-box-shadow: 0 0.72px 1.44px 0 rgba(85, 105, 135, 0.1);
                -moz-box-shadow: 0 0.72px 1.44px 0 rgba(85, 105, 135, 0.1);
                h5 {
                    font-size: 20px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    margin-bottom: 14px;
                }
                p {
                    margin-bottom: 30px;
                    padding: 0 0;
                    min-height: 123px;
                    span {
                        font-weight: 400;
                        color: #30C8AC;
                    }
                    a {
                        text-decoration: underline;
                        color: #30C8AC;
                        &:hover {
                            border: none;
                        }
                    }
                }
                a {
                    &.btn { 
                        font-size: 14px;
                        padding: 8px 16px;
                    }
                }
            }
            &.ccoe-steps{
                .feature-colum{
                    position: relative;
                    .ccoe-step{
                        background: #1abcee;
                        padding: 12px 30px;
                        font-size: 15px;
                        font-weight: 600;
                        color: #FFF;
                        position: absolute;
                        top: -24px;
                        left:50%;
                        margin-left: -65px;
                        text-transform: uppercase;
                        width: 130px;
                        -webkit-border-radius: 6px;
                        -moz-border-radius: 6px;
                        border-radius: 6px;
                    }
                }
            }
        }
    }
    @include smalldesk{
        padding: 50px 0 60px;
    }
    &.mantech-fea{
        
        .features-row{
            display: flex;
            gap: 0;
            &.feature-title{
                padding: 25px 0;
                h2{
                    color:#28275a; 
                    font-size:22px; 
                    margin: 10px 0 10px;
                }
            }
            .features-col{
                width: 25%;
                gap: 0;
                padding: 0 10px;
                @include ipad{
                    width: 33.3%;
                    margin-bottom: 20px;
                    height: 100%;
                }
                @include tablet{
                    width: 50%;
                }
                @include big-mobile{
                    width: 100%;
                }
            }
        }
    }
    
}

.careerinfo{
    padding: 70px 0 60px;
    background: #FFFFFF;
    h2{
        font-size: 48px;
        color: #28275A;
        font-weight: 600;
        line-height: 55px;
        margin: 0 0 60px;
        font-family: $base-font;
        text-align: center;
        span{
            display: inline-block;
            vertical-align: top;
            font-size: 48px;
            font-weight: 600;
            @include ipad {
                font-size: 40px;
            }
            @include tablet {
                font-size: 28px;
            }
        }
        @include ipad {
            font-size: 40px;
            line-height: 47px;
            margin: 0 0 30px;
        }
        @include tablet {
            font-size: 28px;
            line-height: 37px;
            margin: 0 0 25px;
        }
    }
    .career-wrap{
        position: relative;
        z-index: 2;
        .career-lists{
            .careervid{
                padding-right: 0;
                iframe{
                    vertical-align: top;
                    height: auto;
                    aspect-ratio: 4/3;
                    width: 100%;
                }
                
            }
            .careerdesc{
                background: #FFF;
                padding-bottom: 25px;
                .careerinner{
                    padding: 0 25px;
                    min-height: 190px;
                    padding-top: 25px;
                    h3{
                        font-size: 40px;
                        color: #28275A;
                        font-weight: 600;
                        line-height: 50px;
                        margin: 0 0 20px;
                        font-family: $base-font;
                        span{
                            display: inline-block;
                            vertical-align: top;
                            font-weight: 600;
                            font-size: 40px;
                            @include desktop{
                                font-size: 32px;
                            }
                            @include ipad {
                                font-size: 32px;
                            }
                            @include tablet {
                                font-size: 24px;
                            }
                        }
                        @include desktop{
                            font-size: 32px;
                            line-height: 42px;
                        }
                        @include ipad {
                            font-size: 32px;
                            line-height: 42px;
                        }
                        @include tablet {
                            font-size: 24px;
                            line-height: 32px;
                        }
                    }
                    h4{
                        margin: 0 0 15px;
                        span{
                            display: inline-block;
                            background: #F6EEE3;
                            color: #FFAB40;
                            -webkit-border-radius: 63px;
                            -moz-border-radius: 63px;
                            border-radius: 63px;
                            padding: 6px 30px 8px;
                            font-size: 20px;
                            font-weight: 500;
                            margin-bottom: 0;
                            font-family: $base-font;
                        }
                    }
                    p{
                        font-size: 18px;
                        color: #28275A;
                        margin: 0 0 18px;
                        @include desktop {
                            font-size: 16px;
                        }
                        @include tablet {
                            font-size: 14px;
                        }
                    }
                }
                .career-btn{
                    padding: 20px 25px 0;
                    background: #FFF;
                    @include tablet {
                        padding: 10px 25px 0;
                    }
                }
                
                @include tablet {
                    padding-bottom:30px;
                }

            }
        }
        &:before{
            content: '';
            display: block;
            position: absolute;
            left: -60px;
            top: -60px;
            width: 120px;
            height: 120px;
            background: url(../images/mantech/careerlist-before.png) no-repeat center;
            background-size: 100%;
            z-index: -1;
            @include smalldesk{
                left: -10px;
                top: -60px;
            }
        }
        &:after{
            content: '';
            display: block;
            position: absolute;
            right: -57px;
            bottom: -5px;
            width: 148px;
            height: 90px;
            background: url(../images/mantech/careerlist-after.png) no-repeat center;
            background-size: 100%;
            z-index: -1;
            @include smalldesk{
                right: -10px;
                bottom: -5px;
            }
        }
        .owl-carousel {
            .owl-dots{
                margin-top: 50px;
            }
        }
        .careerca{
            @include smalldesk{
                .careerdesc{
                    display: block;
                    width: 100%;
                    padding: 0 25px 30px;
                    order: 2;
                    
                }
                .careervid{
                    display: block;
                    width: 100%;
                    padding: 0 0;
                    order: 1;
                }
                &.owl-carousel {
                    .owl-nav {
                        button.owl-next{
                            right: -22px;
                        }
                        button.owl-prev{
                            left: -22px;
                        }
                    }
                }
            }
        }
    }
    &.trainings{
        padding: 100px 0 100px;
        .career-wrap{
            background: #FFF;
            box-shadow: 0 4px 23.3px 0 rgba(0,0,0,0.1);
            -webkit-box-shadow: 0 4px 23.3px 0 rgba(0,0,0,0.1);
            -moz-box-shadow: 0 4px 23.3px 0 rgba(0,0,0,0.1);
            border-radius: 8px;
            &:after{
                bottom: -30px;
                background: url(../images/mantech/careerlist-afterTwo.png) no-repeat center;;
            }
            .career-lists{
                .row{
                    margin: 0 0;
                }
            }
            .careervid{
                position: relative;
                height: 100%;
                overflow: hidden;
                
                
                iframe{
                    margin-top: -110px;
                    min-height: 590px;

                    @include smalldesk{
                        margin-top: -126px;
                        min-height: 542px;
                    }
                    @include smaldesk{
                        margin-top: -158px;
                    }
                }
                @include ipad{
                    display: block;
                    width: 100%;
                    padding: 0 0 0;
                    order: 2;
                    .careeriframe{
                        max-width: 530px;
                        margin:0 auto;
                    }
                }
                @include until-mob{
                    .careeriframe{
                        max-width: 300px;
                        iframe{
                            margin-top: -298px;
                            min-height: 500px;
                        }
                    }
                }
            }
            .career-lists {
                
                .careerdesc{
                    @include smalldesk{
                        
                    }
                    @include ipad{
                        order: 1;
                        text-align: center;
                        width: 100%;
                    }

                }
            }
        }
    }
}

.aboutmantech{
    background: #28275A;
    padding: 80px 0 80px;
    .about-content{
        .content-inner{
            max-width: 520px;
            h2{
                font-size: 40px;
                color: #FFF;
                font-weight: 600;
                line-height: 50px;
                margin: 0 0 35px;
                font-family: $base-font;
                span{
                    display: inline-block;
                    vertical-align: top;
                    font-size: 40px;
                    font-weight: 600;
                }
                @include ipad{
                    font-size: 35px;
                    line-height: 45px;
                    margin: 0 0 20px;
                    span{
                        font-size: 35px;
                    }
                }
                @include tablet{
                    font-size: 28px;
                    line-height: 34px;
                    span{
                        font-size: 28px;
                    }
                }
            }
            p{
                font-size: 18px;
                color: #FFF;
                margin: 0 0 25px;
                line-height: 32px;
                @include ipad{
                    font-size: 16px;
                    line-height:28px;
                    margin: 0 0 20px;
                }
                @include tablet{
                    font-size: 14px;
                    line-height:24px;
                }
            }
            .btn-primary{
                margin-top: 20px;
            }
            @include tablet{
                padding-bottom: 35px;
            }
        }
    }
    .about-image{
        img{
            width: 100%;
        }
    }
    @include ipad{
        padding: 60px 0 60px;
    }
}

.getform{
    background: #F7F8F9;
    padding: 80px 0 80px;
    .getform-head{
        max-width: 800px;
        margin: 0 auto 26px;
        text-align: center;
    }
    h2{
        font-size: 48px;
        color: #28275A;
        font-weight: 600;
        line-height: 55px;
        margin: 0 0 35px;
        font-family: $base-font;
        text-align: center;
        span{
            display: inline-block;
            vertical-align: top;
            font-size: 48px;
            font-weight: 600;
            @include ipad {
                font-size: 40px;
            }
            @include tablet {
                font-size: 28px;
            }
        }
        @include ipad {
            font-size: 40px;
            line-height: 47px;
            margin: 0 0 30px;
        }
        @include tablet {
            font-size: 28px;
            line-height: 37px;
            margin: 0 0 25px;
        }
    }
    p{
        font-size: 18px;
        color: #28275A;
        @include desktop {
            font-size: 16px;
        }
        @include tablet {
            font-size: 14px;
        }
    }
    .mantechform{
        max-width: 950px;
        margin: 0 auto;
        .formhead{
            margin-bottom: 20px;
            .form-group{
                margin: 0 0 18px;
                &>label{
                    color: #28275A;
                    font-size: 16px;
                    font-weight: 500;
                    min-width: 200px;
                    @include tablet {
                        font-size: 14px;
                        min-width: 166px;
                    }
                }
            }
        }
        .form-group{
            margin: 0 0 20px;
        }
        .form-control{
            padding: 8px 15px;
            @include tablet {
                font-size: 14px;
            }
        }
        .form-control, .form-check-input{
            border: #ced4da 1px solid;
            
            &:focus{
                box-shadow: none;
                outline: 0;
                border: #28275A 1px solid;
            }
        }
        .form-check-inline{
            @include tablet {
                margin-right: 12px;
            }
        }
        .form-check-label{
            @include tablet {
                font-size: 14px;
            }
        }
        .btn-primary{
            float: right;
            margin-top: 15px;
            background: #FFAB40;
            border-color: #FFAB40;
        }
    }
    @include smalldesk{
        padding: 50px 0 60px;
    }
}
.ccoe{
    .features-mantech {
        .features-row{
            display: block;
        }
        &.feature-divTwo{
            .features-row{
                display: flex;
            }
        }
    }
    .career-post {
        .careerpost {
            .postimg {
                position: relative;
                .level{
                    display: block;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    position: absolute;
                    line-height: 30px;
                    font-size: 16px;
                    color: #FFF;
                    font-weight: 500;
                    text-align: center;
                    &.ent{
                        background: #28275A;
                    }
                    &.mid{
                        background: #30C8AC;
                    }
                    &.adv{
                        background: #FFAB40;
                    }
                }
            }
            &.flip-card{
                perspective: 1000px;
                min-height: 565px;
                .flip-card-inner {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    transition: transform 0.8s;
                    transform-style: preserve-3d;
                        .flip-card-front, .flip-card-back {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            -webkit-backface-visibility: hidden; /* Safari */
                            backface-visibility: hidden;
                      }
                      .flip-card-back {
                        color: white;
                        transform: rotateY(180deg);
                        padding: 10px 10px;
                        h5{
                            font-size: 22px;
                            margin: 0 0 10px;
                        }
                        .career-level{
                            font-size: 14px;
                            padding: 2px 9px;
                            margin: 0 0 16px;
                            display: inline-block;
                        }
                        ul{
                            list-style: none;
                            padding: 0 0 0;
                            margin:0 0 16px;
                            li{
                                list-style:none;
                                padding: 0 0 0 5px;
                                margin: 0 0 0;
                                font-size: 14px;
                                color: #28275a;
                                &:before{
                                    content: '-';
                                    margin-right: 5px;
                                }
                                &.heading{
                                    font-weight: 500;
                                    list-style: none;
                                    padding: 0 0 0;
                                    &:before{
                                        content: '';
                                    }
                                }
                            }
                        }
                        &.mid{
                            .career-level{
                                background: #30C8AC;
                            }
                        }
                        &.ent{
                            .career-level{
                                background: #28275A;
                            }
                        }
                        &.adv{
                            .career-level{
                                background: #FFAB40;
                            }
                        }
                        &:after, &:before{
                            content: '';
                            clear: both;
                            display: table;
                        }
                        .close-mo{
                            position: absolute;
                            right: 0;
                            top: 0;
                            background: url(../images/close.png) no-repeat;
                            width: 30px;
                            height: 30px;
                            display: block;
                        }
                        .flipdetail{
                            font-size: 14px;
                            padding: 8px 16px;
                            border: none;
                            border-radius: 5px;
                            margin: 15px 0 0;
                        }
                      }
                      &.show{
                        transform: rotateY(180deg);
                      }
                  }
                  @include big-mobile{
                    min-height: 580px;
                }
            }
        }
        
    }
    
}
.model-int{
    .modal-dialog{
        max-width: 700px;
        .modal-header{
            border-bottom: 0;
            padding: 20px 20px 0;
            justify-content: left;
            h5{
                font-size: 32px;
                font-weight: 500;
                font-family: $base-font;
                margin: 0 0 0;
                img{
                  max-width: 72px;
                  margin-right: 20px;
                }
            }
        }
        .model-colum{
            justify-content: end;
            display: flex;
            flex-direction: column;
            text-align: center;
            align-items: center;
            margin-bottom: 25px;
            p{
                font-size: 14px;
                font-weight: 500;
                color: #28275A;
                font-family: $base-font;
                min-height: 65px;
                span{
                    color: #30C8AC;
                }
            }
            img{
                margin-bottom: 23px;
            }
            &:first-child{
                img{
                    max-width: 90px;
                }
            }
            &:nth-child(2){
                img{
                    max-width: 210px;
                }
            }
            &:nth-child(3){
                img{
                    max-width: 197px;
                }
            }
        }
        .modal-body{
            text-align: center;
            .btn-primary{
                margin: 30px 0 28px;
                &:hover, &:focus{
                    background: #ffab40;
                    outline: 0;
                    box-shadow: none;
                }
            }
        }
        .btn-close{
            position: absolute;
            right: 5px;
            top: 5px;
            background: url(../images/close.png) no-repeat;
            width: 30px;
            height: 30px;
            display: block;
            -webkit-filter:none;
             filter: none;
             margin: 0 0 0;
             padding: 0 0 0;
             
        }
    }
}
@include tablet{
    .mantechpage.ccoe {
        .owl-carousel {
            .owl-nav { 
                display: none;

            }
        }
    }
}