// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------
body{
    font-family: $base-font;
    font-weight: normal;
    font-size: 18px;
    color: $text-color;
}
a{
    color:#ffab40;
    text-decoration: none;
    &:hover{
        color:#ffab40;
        text-decoration: underline;
    }
}