// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------
.three-widget.services-section{
    margin-top: 60px;
    text-align: center;
    &:before{
        top: 129px;
    }
    h2{
        text-align: center;
        color: #28275a;
        margin-bottom: 39px;
    }
    .service-col {
        .service-inner {
            padding-top: 50px;
            position: relative;
            img{
                max-height: 85px;
                margin: 0 0 35px;
            }
            h3{
                font-weight: normal;
                font-size: 23px;
                color: #28275A;
                a{
                    text-decoration: none;
                    font-weight: normal;
                    font-size: 20px;
                    color: #28275A;
                    span{
                        color: #30C8AC;
                    }
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
            .service-description{
                min-height: 85px;
            }
            a.btn-primary{
                padding: 15px 15px;
                min-width: 200px;
                display: inline-block;
            }
            .datepost{
                position: absolute;
                top: 0;
                right: 24px;
                background: #28275A;
                font-size: 10px;
                font-weight: 500;
                text-align: center;
                color: #FFF;
                padding: 11px 4px;
                span{
                    display: block;
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 30px;
                }
            }
        }
        @include smalldesk{
            margin-bottom: 30px;
        }
        @include tablet{
            width: 100%;
            .service-inner {
                img{
                    max-width: 100%;
                }
            }
        }
    }
}

.pagenotfound{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 450px;
    h1{
        text-align: center;
        font-size: 90px;
        font-family: $base-font;
        font-weight: bold;
        line-height: 65px;
        margin: 0 0 0;
        span{
            display: block;
            font-size: 30px;
            font-weight: bold;
            &.error{
                font-size: 22px;
                color: red;
            }
        }
    }
}